<template>
  <section>
    <vx-brand-bar-render :brand-bar-data='brandBarData'>
    </vx-brand-bar-render>
  </section>
</template>
<script>
export default {
  name: 'vx-brand-bar',
  components: { vxBrandBarRender: () => import(/* webpackChunkName: "vx-brand-bar" */ './vx-brand-bar.vue') },
  props: {
    brandBarData: {
      type: Object,
      required: true,
      default: {},
    },
  },
};
</script>
