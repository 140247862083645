<template>
  <section>
    <vx-product-carousel-render :product-carousel-data='productCarouselData'>
    </vx-product-carousel-render>
  </section>
</template>
<script>
export default {
  name: 'vx-product-carousel',
  components: { vxProductCarouselRender: () => import(/* webpackChunkName: "vx-product-carousel" */ './vx-product-carousel.vue') },
  props: {
    productCarouselData: {
      type: Object,
      default: () => ({
        componentHeader: 'Dixie Ultra® Interfold Napkin Dispensers',
        componentTheme: 'product-solution ruler',
        ctaText: 'Learn More',
        ctaStyle: 'btn btn-primary',
        ctaLink: 'https://www.google.com',
        noOfColumns: '5',
        isCarousel: false,
        products: [
          {
            name: 'sample',
            url: '#',
            code: 'Cost Effective',
            price: '890.78',
            imageUrl:
              'https://www.gppro.com/-/media/cpg/gpprofessional/img/serve-greatness/cafeteria/saladcoldbar/l11pl.png?la=en',
          },
          {
            name: 'sample',
            url: '#',
            code: 'Cost Effective',
            price: '890.78',
            imageUrl:
              'https://www.gppro.com/-/media/cpg/gpprofessional/img/serve-greatness/cafeteria/saladcoldbar/sxp9path.png?la=en',
          },
          {
            name: 'sample',
            url: '#',
            code: 'Cost Effective',
            price: '890.78',
            imageUrl:
              'https://www.gppro.com/-/media/cpg/gpprofessional/img/serve-greatness/cafeteria/saladcoldbar/toc.png?la=en',
          },
          {
            name: 'sample',
            url: '#',
            code: 'Cost Effective',
            price: '890.78',
            imageUrl:
              'https://www.gppro.com/-/media/cpg/gpprofessional/img/serve-greatness/cafeteria/saladcoldbar/ch56nc7.png?la=en',
          },
          {
            name: 'sample',
            url: '#',
            code: 'Cost Effective',
            price: '890.78',
            imageUrl:
              'https://www.gppro.com/-/media/cpg/gpprofessional/img/serve-greatness/cafeteria/saladcoldbar/sxp9path.png?la=en',
          },
          {
            name: 'sample',
            url: '#',
            code: 'Cost Effective',
            price: '890.78',
            imageUrl:
              'https://www.gppro.com/-/media/cpg/gpprofessional/img/serve-greatness/cafeteria/saladcoldbar/toc.png?la=en',
          },
          {
            name: 'sample',
            url: '#',
            code: 'Cost Effective',
            price: '890.78',
            imageUrl:
              'https://www.gppro.com/-/media/cpg/gpprofessional/img/serve-greatness/cafeteria/saladcoldbar/sxp9path.png?la=en',
          },
          {
            name: 'sample',
            url: '#',
            code: 'Cost Effective',
            price: '890.78',
            imageUrl:
              'https://www.gppro.com/-/media/cpg/gpprofessional/img/serve-greatness/cafeteria/saladcoldbar/toc.png?la=en',
          },
        ],
      }),
    },
  },
};
</script>
