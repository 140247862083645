var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "section",
    { staticClass: "search-browse-auto-suggest d-flex flex-column" },
    [
      _c("p"),
      !_vm.isDeviceAndroid()
        ? _c("div", { staticClass: "sr-only" }, [
            _vm._v(_vm._s(_vm.i18n.allySearchLabel))
          ])
        : _vm._e(),
      _vm._v(" "),
      _c("div", { staticClass: "d-flex justify-content-center" }, [
        _c("div", { staticClass: "inner-addon" }, [
          _c("div", { staticClass: "right-addon mr-sm-0 mr-xs-2 d-flex" }, [
            _vm.isB2B
              ? _c(
                  "div",
                  { staticClass: "dropdown" },
                  [
                    _c("vx-dropdown-primary", {
                      ref: "category",
                      attrs: { dropdownValues: _vm.categoryValues },
                      on: {
                        "selected-option": function($event) {
                          _vm.getSelectedDropdown($event)
                        }
                      }
                    })
                  ],
                  1
                )
              : _vm._e(),
            _vm._v(" "),
            _c("div", { staticClass: "left-addon" }, [
              _vm.clearTextIcon
                ? _c("button", {
                    staticClass: "icon-x",
                    attrs: {
                      tabindex: "0",
                      "aria-label": _vm.i18n.iconXTitle,
                      title: _vm.i18n.iconXTitle
                    },
                    on: {
                      click: function($event) {
                        _vm.clearSearch($event)
                      },
                      keyup: function($event) {
                        if (
                          !("button" in $event) &&
                          _vm._k(
                            $event.keyCode,
                            "enter-space",
                            undefined,
                            $event.key,
                            undefined
                          )
                        ) {
                          return null
                        }
                        _vm.clearSearch($event)
                      }
                    }
                  })
                : _vm._e(),
              _vm._v(" "),
              !_vm.searchIconDisabled
                ? _c("span", {
                    staticClass: "icon-search",
                    attrs: {
                      title: _vm.i18n.iconSearchTitle,
                      "aria-hidden": "true"
                    }
                  })
                : _vm._e(),
              _vm._v(" "),
              _c(
                "label",
                { staticClass: "sr-only", attrs: { for: "searchProduct" } },
                [
                  _c("span", { attrs: { "aria-hidden": "true" } }, [
                    _vm._v(_vm._s(_vm.i18n.allySearchLabel))
                  ])
                ]
              ),
              _vm._v(" "),
              _c("input", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.searchTerm,
                    expression: "searchTerm"
                  }
                ],
                staticClass: "form-control autosuggest-input",
                class: { searchbar: _vm.isB2B },
                attrs: {
                  id: "searchProduct",
                  type: "search",
                  placeholder: _vm.i18n.iconSearchTitle
                },
                domProps: { value: _vm.searchTerm },
                on: {
                  keyup: _vm.autosuggest,
                  input: [
                    function($event) {
                      if ($event.target.composing) {
                        return
                      }
                      _vm.searchTerm = $event.target.value
                    },
                    function($event) {
                      _vm.searchTerm = $event.target.value
                    }
                  ]
                }
              })
            ])
          ])
        ])
      ]),
      _vm._v(" "),
      _c("p"),
      _vm._v(" "),
      _vm.productList.length > 0
        ? _c(
            "div",
            {
              staticClass: "d-flex justify-content-center",
              on: {
                click: function($event) {
                  $event.stopPropagation()
                }
              }
            },
            [
              _vm.showAutosuggestPanel
                ? _c("div", { staticClass: "autosuggest-panel" }, [
                    _c(
                      "div",
                      {
                        staticClass: "d-flex view-all justify-content-between"
                      },
                      [
                        _c(
                          "h2",
                          {
                            staticClass: "heading",
                            attrs: { role: "heading" }
                          },
                          [_vm._v(_vm._s(_vm.i18n.heading))]
                        ),
                        _vm._v(" "),
                        _c(
                          "a",
                          {
                            attrs: { href: "#" },
                            on: {
                              click: function($event) {
                                _vm.viewAllClicked($event)
                              },
                              keyup: function($event) {
                                if (
                                  !("button" in $event) &&
                                  _vm._k(
                                    $event.keyCode,
                                    "enter-space",
                                    undefined,
                                    $event.key,
                                    undefined
                                  )
                                ) {
                                  return null
                                }
                                _vm.viewAllClicked($event)
                              }
                            }
                          },
                          [
                            _c("h2", [
                              _vm._v(
                                _vm._s(_vm.i18n.viewAllLinkText) +
                                  ' "' +
                                  _vm._s(_vm.searchTerm) +
                                  '"'
                              )
                            ])
                          ]
                        )
                      ]
                    ),
                    _vm._v(" "),
                    _c(
                      "div",
                      {
                        staticClass: "d-flex",
                        class:
                          _vm.singleProductEnabled && _vm.isMobile()
                            ? "product-tile-single-product"
                            : "product-tile"
                      },
                      _vm._l(_vm.productList, function(product) {
                        return _c("vx-product-tile-vertical", {
                          key:
                            product && product.code
                              ? product.code + _vm.$index
                              : product,
                          attrs: {
                            "is-bazaar-voice": _vm.isBazaarVoice,
                            product: product,
                            "is-favorite-visible": _vm.isFavorites,
                            "is-item-id-hide": false,
                            i18n: _vm.searchBrowseI18n.productTile
                          }
                        })
                      })
                    )
                  ])
                : _vm._e()
            ]
          )
        : _vm._e()
    ]
  )
}
var staticRenderFns = []
render._withStripped = true
export { render, staticRenderFns }
if (module.hot) {
  module.hot.accept()
  if (module.hot.data) {
    require("vue-hot-reload-api")      .rerender("data-v-267c1592", { render: render, staticRenderFns: staticRenderFns })
  }
}