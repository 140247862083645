import vxThumbnailViewer from '../components/b2c/pdp/vx-thumbnail-viewer/vx-thumbnail-viewer-shell';
import vxPdpProductInfo from '../components/b2c/pdp/vx-pdp-product-info/vx-pdp-product-info-shell';
import vxTabContainer from '../components/b2c/pdp/vx-tab-container/vx-tab-container-shell';

const pdpModule = {
  components: {
    vxThumbnailViewer,
    vxPdpProductInfo,
    vxTabContainer,
  },
};

export default pdpModule;
