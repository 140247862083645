var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "section",
    [
      _c("vx-contact-us-render", {
        attrs: {
          i18n: _vm.$root.messages.accessCustomerService,
          "live-chat-theme": _vm.liveChatTheme,
          "show-contact-us": _vm.showContactUs,
          "image-text-data": _vm.imageTextData,
          "is-live-chat-enabled": _vm.isLiveChatEnabled
        }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true
export { render, staticRenderFns }
if (module.hot) {
  module.hot.accept()
  if (module.hot.data) {
    require("vue-hot-reload-api")      .rerender("data-v-657375b8", { render: render, staticRenderFns: staticRenderFns })
  }
}