<template>
  <section>
    <vx-search-result-render :category-id="categoryId" :is-favorites="isFavorites"
      :is-bazaar-voice="isBazaarVoice" :is-content-enabled="isContentEnabled"
      :is-bulk-enabled="isBulkEnabled" :search-browse-i18n="$root.messages.searchBrowse"
      :is-sample-cart="isSampleCart" :single-product-enabled="singleProductEnabled">
    </vx-search-result-render>
  </section>
</template>
<script>
export default {
  name: 'vx-search-result',
  components: {
    vxSearchResultRender: () => import(/* webpackChunkName: "vx-search-result" */ './vx-search-result.vue'),
  },
  props: {
    // indicates whether the site is configured for favorites
    isFavorites: {
      type: Boolean,
      default: false,
    },
    // indicates whether the site is configured for Bazaar Voice
    isBazaarVoice: {
      type: String,
      default: '',
    },
    // Copy text coming from properties files for search browse components
    searchBrowseI18n: {
      type: Object,
    },
    // indicates whether the site is configured for bulk
    isBulkEnabled: {
      type: Boolean,
      default: false,
    },
    // category id
    categoryId: {
      type: String,
      default: '',
    },
    // indicates whether it is search results page
    searchResultPage: {
      type: Boolean,
      default: false,
    },
    // indicates whether the site is configured for content
    isContentEnabled: {
      type: Boolean,
      default: false,
    },
    // indicates whether the site is configured for single product
    singleProductEnabled: {
      type: Boolean,
      default: false,
    },
  },
};
</script>
