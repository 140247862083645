<template>
  <section>
    <vx-breadcrumb-render :breadcrumbs='breadcrumbs'>
    </vx-breadcrumb-render>
  </section>
</template>
<script>
export default {
  name: 'vx-breadcrumb',
  components: { vxBreadcrumbRender: () => import(/* webpackChunkName: "vx-breadcrumb" */ './vx-breadcrumb.vue') },
  props: {
    // breadcrumb details coming from parent component
    breadcrumbs: {
      type: Array,
    },
  },
};
</script>
