var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "section",
    [
      _c("vx-search-result-render", {
        attrs: {
          "category-id": _vm.categoryId,
          "is-favorites": _vm.isFavorites,
          "is-bazaar-voice": _vm.isBazaarVoice,
          "is-content-enabled": _vm.isContentEnabled,
          "is-bulk-enabled": _vm.isBulkEnabled,
          "search-browse-i18n": _vm.$root.messages.searchBrowse,
          "is-sample-cart": _vm.isSampleCart,
          "single-product-enabled": _vm.singleProductEnabled
        }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true
export { render, staticRenderFns }
if (module.hot) {
  module.hot.accept()
  if (module.hot.data) {
    require("vue-hot-reload-api")      .rerender("data-v-3b47fed4", { render: render, staticRenderFns: staticRenderFns })
  }
}