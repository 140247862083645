<template>
  <section>
    <vx-contact-us-render :i18n="$root.messages.accessCustomerService" :live-chat-theme='liveChatTheme'
      :show-contact-us='showContactUs' :image-text-data='imageTextData'
      :is-live-chat-enabled='isLiveChatEnabled'>
    </vx-contact-us-render>
  </section>
</template>
<script>
export default {
  name: 'vx-contact-us',
  components: {
    vxContactUsRender: () => import(/* webpackChunkName: "vx-contact-us" */ './vx-contact-us.vue'),
  },
  props: {
    // Text coming from property file
    i18n: {
      type: Object,
      default: {},
    },
    // Dynamic css class for live chat
    liveChatTheme: {
      type: String,
      required: false,
    },
    // Shows call us section
    showContactUs: {
      type: Boolean,
      required: true,
      default: true,
    },
    // Data about chat section
    imageTextData: {
      type: Object,
      required: false,
    },
    // Indicates if live chat section is enabled on contact us page
    isLiveChatEnabled: {
      type: Boolean,
      default: false,
      required: true,
    },
    // Topic of inquiry dropdown data
    topicOfInquiry: {
      type: String,
      default: '',
      required: true,
    },
  },
};
</script>
