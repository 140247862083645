/**
  vx-marketing-form-sms is used for displaying content inside forms in smart edit
*/
<template>
  <section>
    <vx-marketing-form-sms-render><slot></slot></vx-marketing-form-sms-render>
  </section>
</template>
<script>
export default {
  name: 'vx-marketing-form-sms',
  components: { vxMarketingFormSmsRender: () => import(/* webpackChunkName: "vx-marketing-form-sms" */ './vx-marketing-form-sms.vue') },
};
</script>
