var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      directives: [
        {
          name: "lazy-container",
          rawName: "v-lazy-container",
          value: { selector: "img" },
          expression: "{ selector: 'img' }"
        }
      ],
      staticClass: "search-browse-product-tile d-flex"
    },
    [
      _c("vx-spinner", {
        ref: "spinner",
        attrs: {
          image: _vm.globals.assetsPath + "images/spinner.gif",
          "full-screen": ""
        }
      }),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "tile-body d-flex flex-column" },
        [
          _c("div", { staticClass: "tile-image" }, [
            _c(
              "a",
              {
                staticClass: "d-flex justify-content-center",
                attrs: {
                  tabindex: "0",
                  "aria-label": _vm.product.name
                    ? _vm.product.name
                    : _vm.i18n.noProductName,
                  href: _vm.pdpContextPath + _vm.product.url
                }
              },
              [
                _vm.product.images &&
                _vm.product.images[0] &&
                _vm.product.images[0].url
                  ? _c("img", {
                      attrs: {
                        "data-src": _vm.globals.getThumbnailImageUrl(
                          _vm.product.images
                        ),
                        "data-loading":
                          _vm.globals.assetsPath + "images/spinner.gif",
                        alt: _vm.product.name
                          ? _vm.product.name
                          : _vm.i18n.noProductName
                      }
                    })
                  : _vm._e(),
                _vm._v(" "),
                !_vm.product.images
                  ? _c("img", {
                      attrs: {
                        "data-src":
                          _vm.globals.assetsPath + "images/no_image.svg",
                        "data-loading":
                          _vm.globals.assetsPath + "images/spinner.gif",
                        alt: _vm.product.name
                          ? _vm.product.name
                          : _vm.i18n.noProductName
                      }
                    })
                  : _vm._e()
              ]
            )
          ]),
          _vm._v(" "),
          _vm.product.name
            ? [
                _vm.isTruncatedName
                  ? _c(
                      "a",
                      {
                        staticClass: "product-name pt-md-2 my-sm-0",
                        attrs: {
                          href: _vm.pdpContextPath + _vm.product.url,
                          "data-toggle": "tooltip",
                          title: _vm.getProductName(_vm.product.name)
                        }
                      },
                      [
                        _c("p", {
                          domProps: {
                            innerHTML: _vm._s(
                              _vm.formatProductName(
                                _vm.product.name,
                                _vm.characterLimit
                              )
                            )
                          }
                        })
                      ]
                    )
                  : _vm._e(),
                _vm._v(" "),
                !_vm.isTruncatedName
                  ? _c(
                      "a",
                      {
                        staticClass: "product-name pt-md-2 my-sm-0",
                        attrs: { href: _vm.pdpContextPath + _vm.product.url }
                      },
                      [
                        _c("p", {
                          domProps: {
                            innerHTML: _vm._s(
                              _vm.formatProductName(
                                _vm.product.name,
                                _vm.characterLimit
                              )
                            )
                          }
                        })
                      ]
                    )
                  : _vm._e()
              ]
            : _vm._e(),
          _vm._v(" "),
          _vm.isBazaarVoice === "true"
            ? _c("div", [
                _vm.product.bvAverageRating
                  ? _c(
                      "div",
                      { staticClass: "star-rating py-xs-2" },
                      [
                        _c("vx-star-rating", {
                          attrs: {
                            "product-rating": _vm.product.bvAverageRating,
                            "product-reviews": _vm.product.bvNumberOfReviews,
                            i18n: _vm.i18n,
                            "show-rating": _vm.showRating,
                            "show-reviews": _vm.showReviews,
                            "number-of-stars": 5
                          }
                        })
                      ],
                      1
                    )
                  : _vm._e()
              ])
            : _vm._e(),
          _vm._v(" "),
          _c(
            "button",
            {
              staticClass: "btn btn-primary tile-button",
              attrs: {
                type: "button",
                role: "link",
                "aria-label":
                  _vm.getProductName(_vm.product.name) +
                  " " +
                  _vm.i18n.label.findAStore
              },
              on: {
                click: _vm.findAStore,
                keyup: function($event) {
                  if (
                    !("button" in $event) &&
                    _vm._k(
                      $event.keyCode,
                      "enter-space",
                      undefined,
                      $event.key,
                      undefined
                    )
                  ) {
                    return null
                  }
                  return _vm.findAStore($event)
                }
              }
            },
            [_vm._v(_vm._s(_vm.i18n.label.findAStore))]
          )
        ],
        2
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true
export { render, staticRenderFns }
if (module.hot) {
  module.hot.accept()
  if (module.hot.data) {
    require("vue-hot-reload-api")      .rerender("data-v-43a415d3", { render: render, staticRenderFns: staticRenderFns })
  }
}