<template>
  <section>
    <vx-live-chat-render :i18n='$root.messages.accessCustomerService.liveChat' :is-live-chat-enabled='isLiveChatEnabled'>
    </vx-live-chat-render>
  </section>
</template>
<script>
export default {
  name: 'vx-live-chat',
  components: {
    vxLiveChatRender: () => import(/* webpackChunkName: "vx-live-chat" */ './vx-live-chat.vue'),
  },
  props: {
    // Text and label from properties file
    i18n: {
      type: Object,
      required: true,
    },
    // Dynamic css class for live chat
    liveChatTheme: {
      type: String,
      required: false,
    },
    // Shows call us section
    showContactUs: {
      type: Boolean,
      required: true,
      default: true,
    },
    // Data about chat section
    imageTextData: {
      type: Object,
      required: false,
    },
    // Indicates if live chat section is enabled on contact us page
    isLiveChatEnabled: {
      type: Boolean,
      default: false,
      required: true,
    },
    // Contact email to be passed from jsp
    contactUsEmail: {
      type: String,
      default: '',
      required: false,
    },
  },
};
</script>
