<template>
  <section>
    <vx-juicer-feed-render :social-aggregator-options='socialAggregatorOptions'>
    </vx-juicer-feed-render>
  </section>
</template>
<script>
export default {
  name: 'vx-juicer-feed',
  components: { vxJuicerFeedRender: () => import(/* webpackChunkName: "vx-juicer-feed" */ './vx-juicer-feed.vue') },
  props: {
    /**
     * all the properties for social aggregator
     */
    socialAggregatorOptions: {
      type: Object,
    },
  },
};
</script>
