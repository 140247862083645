var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c(
      "header",
      {
        staticClass:
          "header-section navbar-fixed-top non-printable-section row ml-xs-0"
      },
      [
        _c("vx-spinner", {
          ref: "spinner",
          attrs: {
            image: _vm.globals.assetsPath + "images/spinner.gif",
            "full-screen": ""
          }
        }),
        _vm._v(" "),
        !_vm.headerOptions.isCheckout
          ? _c(
              "nav",
              {
                staticClass:
                  "non-printable-section navbar-top d-flex align-items-center justify-content-between",
                class: { "vanity-fair-mobile-nav-color": _vm.showMobileNav },
                attrs: { role: "navigation" }
              },
              [
                _c(
                  "button",
                  {
                    staticClass: "icon-menu ml-xs-3 py-xs-3",
                    attrs: {
                      title: _vm.i18n.iconMenuTitle,
                      tabindex: "0",
                      role: "button",
                      "aria-label": _vm.i18n.iconMenuTitle,
                      href: "javascript:void(0)",
                      "aria-expanded": _vm.showMobileNav ? "true" : "false"
                    },
                    on: {
                      click: function($event) {
                        _vm.showMobileNav = !_vm.showMobileNav
                        _vm.updateAria(_vm.showMobileNav)
                        _vm.setHeaderHeight()
                      }
                    }
                  },
                  [
                    _c("span", { staticClass: "sr-only" }, [
                      _vm._v("Main Navigation")
                    ])
                  ]
                ),
                _vm._v(" "),
                _vm.isHomePage
                  ? [
                      _vm.isTablet()
                        ? _c(
                            "a",
                            {
                              staticClass: "logo col-xs-5",
                              attrs: {
                                itemscope: "",
                                itemtype:
                                  _vm.globals.logoContentSchema.itemType,
                                tabindex: "0",
                                role: "link",
                                "aria-hidden":
                                  _vm.showMobileNav && _vm.isTablet(),
                                "aria-label": _vm.globals.getSiteName()
                              },
                              on: {
                                click: function($event) {
                                  _vm.globals.navigateToUrl("home")
                                }
                              }
                            },
                            [
                              _vm.globals.siteConfig.isBrandLogoMobile &&
                              _vm.isTablet()
                                ? _c("img", {
                                    staticClass: "ml-xs-3",
                                    attrs: {
                                      itemprop:
                                        _vm.globals.logoContentSchema.itemProp,
                                      src:
                                        _vm.globals.assetsPath +
                                        _vm.headerOptions.brandMobileLogo,
                                      alt: _vm.globals.getSiteName()
                                    }
                                  })
                                : _c("img", {
                                    staticClass: "ml-xs-3",
                                    attrs: {
                                      itemprop:
                                        _vm.globals.logoContentSchema.itemProp,
                                      src:
                                        _vm.globals.assetsPath +
                                        _vm.headerOptions.brandLogo,
                                      alt: _vm.globals.getSiteName()
                                    }
                                  })
                            ]
                          )
                        : _vm._e()
                    ]
                  : [
                      _vm.isTablet()
                        ? _c(
                            "a",
                            {
                              staticClass: "logo",
                              attrs: {
                                itemtype:
                                  _vm.globals.logoContentSchema.itemType,
                                tabindex: "0",
                                role: "link",
                                "aria-hidden":
                                  _vm.showMobileNav && _vm.isTablet(),
                                "aria-label": _vm.globals.getSiteName()
                              },
                              on: {
                                click: function($event) {
                                  _vm.globals.navigateToUrl("home")
                                }
                              }
                            },
                            [
                              _vm.globals.siteConfig.isBrandLogoMobile &&
                              _vm.isTablet()
                                ? _c("img", {
                                    staticClass: "ml-xs-3",
                                    attrs: {
                                      itemprop:
                                        _vm.globals.logoContentSchema.itemProp,
                                      src:
                                        _vm.globals.assetsPath +
                                        _vm.headerOptions.brandMobileLogo,
                                      alt: _vm.globals.getSiteName()
                                    }
                                  })
                                : _c("img", {
                                    staticClass: "ml-xs-3",
                                    attrs: {
                                      src:
                                        _vm.globals.assetsPath +
                                        _vm.headerOptions.brandLogo,
                                      itemprop:
                                        _vm.globals.logoContentSchema.itemProp,
                                      alt: _vm.globals.getSiteName()
                                    }
                                  })
                            ]
                          )
                        : _vm._e()
                    ],
                _vm._v(" "),
                _c("div", { staticClass: "profile-mobile-dropdown col-xs-7" }, [
                  !_vm.headerOptions.isCheckout
                    ? _c(
                        "div",
                        {
                          staticClass:
                            "non-printable-section d-flex navbar-bottom-right justify-content-between align-items-center",
                          attrs: {
                            "aria-hidden": _vm.showMobileNav && _vm.isTablet()
                          }
                        },
                        [
                          _vm.headerOptions.includeSearchBox
                            ? _c(
                                "div",
                                {
                                  staticClass: "col-xs-11",
                                  class: {
                                    "align-search": !_vm.headerOptions
                                      .includeMiniCart
                                  }
                                },
                                [
                                  _vm._t(
                                    "search",
                                    [
                                      _vm._v(
                                        "\n              Default body\n            "
                                      )
                                    ],
                                    { toggleSearchIcon: _vm.toggleSearchIcon }
                                  )
                                ],
                                2
                              )
                            : _vm._e()
                        ]
                      )
                    : _vm._e()
                ])
              ],
              2
            )
          : _vm._e(),
        _vm._v(" "),
        _c(
          "nav",
          {
            staticClass:
              "navbar-bottom d-flex align-items-center justify-content-between",
            class: { "checkout-header": _vm.headerOptions.isCheckout },
            attrs: { role: "navigation" }
          },
          [
            _c(
              "div",
              { staticClass: "header-left-section d-flex align-items-center" },
              [
                _vm.isHomePage
                  ? [
                      !_vm.tabletView || _vm.headerOptions.isCheckout
                        ? _c(
                            "a",
                            {
                              staticClass: "logo",
                              attrs: {
                                itemscope: "",
                                itemtype:
                                  _vm.globals.logoContentSchema.itemType,
                                tabindex: "0"
                              },
                              on: {
                                click: function($event) {
                                  _vm.globals.navigateToUrl("home")
                                }
                              }
                            },
                            [
                              _vm.globals.siteConfig.isBrandLogoMobile &&
                              _vm.isTablet()
                                ? [
                                    _c("img", {
                                      attrs: {
                                        src:
                                          _vm.globals.assetsPath +
                                          _vm.headerOptions.brandMobileLogo,
                                        itemprop:
                                          _vm.globals.logoContentSchema
                                            .itemProp,
                                        alt: _vm.globals.getSiteName()
                                      }
                                    })
                                  ]
                                : [
                                    _vm.globals.siteConfig.showCheckoutLogo &&
                                    _vm.headerOptions.isCheckout
                                      ? _c("img", {
                                          attrs: {
                                            itemprop:
                                              _vm.globals.logoContentSchema
                                                .itemProp,
                                            src:
                                              _vm.globals.assetsPath +
                                              _vm.headerOptions.checkoutLogo,
                                            alt: _vm.globals.getSiteName()
                                          }
                                        })
                                      : _vm.unitHeaderLogo
                                      ? _c("img", {
                                          attrs: {
                                            itemprop:
                                              _vm.globals.logoContentSchema
                                                .itemProp,
                                            src: _vm.unitHeaderLogo,
                                            alt: _vm.globals.getSiteName()
                                          }
                                        })
                                      : _c("img", {
                                          attrs: {
                                            itemprop:
                                              _vm.globals.logoContentSchema
                                                .itemProp,
                                            src:
                                              _vm.globals.assetsPath +
                                              _vm.headerOptions.brandLogo,
                                            alt: _vm.globals.getSiteName()
                                          }
                                        })
                                  ]
                            ],
                            2
                          )
                        : _vm._e()
                    ]
                  : [
                      !_vm.tabletView || _vm.headerOptions.isCheckout
                        ? _c(
                            "a",
                            {
                              staticClass: "logo",
                              attrs: { tabindex: "0" },
                              on: {
                                click: function($event) {
                                  _vm.globals.navigateToUrl("home")
                                }
                              }
                            },
                            [
                              _vm.globals.siteConfig.isBrandLogoMobile &&
                              _vm.isTablet()
                                ? [
                                    _vm.globals.siteConfig.showCheckoutLogo &&
                                    _vm.headerOptions.isCheckout
                                      ? _c("img", {
                                          attrs: {
                                            src:
                                              _vm.globals.assetsPath +
                                              _vm.headerOptions.checkoutLogo,
                                            alt: _vm.globals.getSiteName()
                                          }
                                        })
                                      : _c("img", {
                                          attrs: {
                                            src:
                                              _vm.globals.assetsPath +
                                              _vm.headerOptions.brandMobileLogo,
                                            alt: _vm.globals.getSiteName()
                                          }
                                        })
                                  ]
                                : [
                                    _vm.globals.siteConfig.showCheckoutLogo &&
                                    _vm.headerOptions.isCheckout
                                      ? _c("img", {
                                          attrs: {
                                            src:
                                              _vm.globals.assetsPath +
                                              _vm.headerOptions.checkoutLogo,
                                            alt: _vm.globals.getSiteName()
                                          }
                                        })
                                      : _vm.unitHeaderLogo
                                      ? _c("img", {
                                          attrs: {
                                            src: _vm.unitHeaderLogo,
                                            alt: _vm.globals.getSiteName()
                                          }
                                        })
                                      : _c("img", {
                                          attrs: {
                                            src:
                                              _vm.globals.assetsPath +
                                              _vm.headerOptions.brandLogo,
                                            alt: _vm.globals.getSiteName()
                                          }
                                        })
                                  ]
                            ],
                            2
                          )
                        : _vm._e()
                    ],
                _vm._v(" "),
                !_vm.headerOptions.isCheckout
                  ? _c(
                      "div",
                      {
                        staticClass:
                          "non-printable-section navbar-bottom-left d-flex pl-xs-0 pl-md-4",
                        class: { "mobile-view": _vm.showMobileNav },
                        attrs: {
                          "aria-expanded": _vm.showMobileNav ? "true" : "false"
                        },
                        on: { click: _vm.dismissMobileMenu }
                      },
                      [
                        _c(
                          "div",
                          {
                            ref: "navigationMenu",
                            staticClass:
                              "navigation-menu d-flex align-items-center pt-xs-3 pt-md-0",
                            on: {
                              click: function($event) {
                                $event.stopPropagation()
                              }
                            }
                          },
                          [
                            _c(
                              "div",
                              { staticClass: "navigation-inner-wrapper" },
                              [
                                _vm.showMobileNav
                                  ? _c(
                                      "div",
                                      { staticClass: "pb-xs-2 pb-md-0" },
                                      [_vm._m(0)]
                                    )
                                  : _vm._e(),
                                _vm._v(" "),
                                _c("div", { staticClass: "pb-xs-2 pb-md-0" }, [
                                  _c(
                                    "ul",
                                    {
                                      staticClass:
                                        "m-xs-0 primary-menu align-self-center d-flex pl-xs-0",
                                      attrs: { role: "list" }
                                    },
                                    _vm._l(_vm.navMenu, function(item, index) {
                                      return _c(
                                        "li",
                                        {
                                          staticClass:
                                            "primary-menu-item js-primary-menu-item p-md-3",
                                          on: {
                                            mouseover: _vm.displayDropDownMenu,
                                            mouseleave: _vm.hideDropDownMenu
                                          }
                                        },
                                        [
                                          item.primary
                                            ? [
                                                _vm.showMobileNav &&
                                                _vm.isSecondaryAvailable(item)
                                                  ? _c(
                                                      "a",
                                                      {
                                                        directives: [
                                                          {
                                                            name: "show",
                                                            rawName: "v-show",
                                                            value:
                                                              _vm.selectedPrimary ===
                                                              "",
                                                            expression:
                                                              "selectedPrimary === ''"
                                                          }
                                                        ],
                                                        staticClass:
                                                          "p-xs-3 p-md-0",
                                                        attrs: {
                                                          href:
                                                            "javascript:void(0)"
                                                        },
                                                        on: {
                                                          click: function(
                                                            $event
                                                          ) {
                                                            _vm.selectPrimary(
                                                              item.primary
                                                            )
                                                          }
                                                        }
                                                      },
                                                      [
                                                        _vm._v(
                                                          "\n                        " +
                                                            _vm._s(
                                                              item.primary
                                                            ) +
                                                            "\n                      "
                                                        )
                                                      ]
                                                    )
                                                  : _c(
                                                      "a",
                                                      {
                                                        directives: [
                                                          {
                                                            name: "show",
                                                            rawName: "v-show",
                                                            value:
                                                              _vm.selectedPrimary ===
                                                              "",
                                                            expression:
                                                              "selectedPrimary === ''"
                                                          }
                                                        ],
                                                        staticClass:
                                                          "p-xs-3 p-md-0",
                                                        attrs: {
                                                          tabindex: "0",
                                                          href: item.external
                                                            ? item.primaryLink
                                                            : _vm.globals
                                                                .contextPath +
                                                              item.primaryLink
                                                        }
                                                      },
                                                      [
                                                        _vm._v(
                                                          "\n                        " +
                                                            _vm._s(
                                                              item.primary
                                                            ) +
                                                            "\n                      "
                                                        )
                                                      ]
                                                    )
                                              ]
                                            : _vm._e(),
                                          _vm._v(" "),
                                          _vm.isSecondaryAvailable(item) &&
                                          !_vm.isTablet()
                                            ? _c("span", {
                                                staticClass:
                                                  "icon-chevron-down",
                                                attrs: {
                                                  "aria-controls": _vm._f(
                                                    "lowercase"
                                                  )(item.primary),
                                                  tabindex: "0",
                                                  "aria-label": _vm._f(
                                                    "lowercase"
                                                  )("Open " + item.primary),
                                                  role: "button"
                                                },
                                                on: {
                                                  click:
                                                    _vm.displayDropDownMenu,
                                                  keyup: [
                                                    function($event) {
                                                      $event.stopPropagation()
                                                      $event.preventDefault()
                                                      return _vm.hideDropDownMenu(
                                                        $event
                                                      )
                                                    },
                                                    function($event) {
                                                      if (
                                                        !("button" in $event) &&
                                                        _vm._k(
                                                          $event.keyCode,
                                                          "enter",
                                                          13,
                                                          $event.key,
                                                          "Enter"
                                                        )
                                                      ) {
                                                        return null
                                                      }
                                                      return _vm.displayDropDownMenu(
                                                        $event
                                                      )
                                                    }
                                                  ]
                                                }
                                              })
                                            : _vm._e(),
                                          _vm._v(" "),
                                          _vm.isSecondaryAvailable(item) &&
                                          !_vm.isTablet()
                                            ? _c(
                                                "div",
                                                {
                                                  staticClass:
                                                    "secondary-menu pt-xs-5 px-md-6 pb-md-5",
                                                  class: {
                                                    "secondary-active":
                                                      _vm.selectedPrimary ===
                                                      item.primary
                                                  },
                                                  attrs: {
                                                    id: _vm._f("lowercase")(
                                                      item.primary
                                                    )
                                                  }
                                                },
                                                [
                                                  _c("div", {
                                                    staticClass: "arrow-up"
                                                  }),
                                                  _vm._v(" "),
                                                  _vm._l(
                                                    item.secondaryList,
                                                    function(secondaryItem) {
                                                      return _c(
                                                        "div",
                                                        {
                                                          staticClass:
                                                            "secondary-category"
                                                        },
                                                        [
                                                          _vm._l(
                                                            _vm.returnItemsImages(
                                                              secondaryItem.menuOptions
                                                            ),
                                                            function(image) {
                                                              return _c(
                                                                "a",
                                                                {
                                                                  attrs: {
                                                                    href:
                                                                      image.link,
                                                                    target: image.isExternalLink
                                                                      ? "_blank"
                                                                      : "_self"
                                                                  }
                                                                },
                                                                [
                                                                  _c("img", {
                                                                    staticClass:
                                                                      "featured-image pl-xs-3",
                                                                    attrs: {
                                                                      src:
                                                                        image.imageSrcD,
                                                                      alt:
                                                                        image.imageSrcAltTextD
                                                                    }
                                                                  })
                                                                ]
                                                              )
                                                            }
                                                          ),
                                                          _vm._v(" "),
                                                          secondaryItem.title
                                                            ? [
                                                                _c("div", {
                                                                  staticClass:
                                                                    "pl-xs-3 sec-title js-secondary-title",
                                                                  domProps: {
                                                                    innerHTML: _vm._s(
                                                                      secondaryItem.title
                                                                    )
                                                                  }
                                                                })
                                                              ]
                                                            : _vm._e(),
                                                          _vm._v(" "),
                                                          _c(
                                                            "ul",
                                                            {
                                                              staticClass:
                                                                "d-flex flex-wrap pl-xs-0 flyout-sec",
                                                              attrs: {
                                                                role: "list"
                                                              }
                                                            },
                                                            _vm._l(
                                                              secondaryItem.menuOptions,
                                                              function(item) {
                                                                return _c(
                                                                  "li",
                                                                  {
                                                                    staticClass:
                                                                      "js-secondary-child p-md-3",
                                                                    class: {
                                                                      "view-all":
                                                                        item.markAsViewAll
                                                                    },
                                                                    attrs: {
                                                                      role:
                                                                        "listitem"
                                                                    }
                                                                  },
                                                                  [
                                                                    item.option
                                                                      ? [
                                                                          _c(
                                                                            "a",
                                                                            {
                                                                              staticClass:
                                                                                "p-xs-3 p-md-0 custom-word-wrap-all",
                                                                              attrs: {
                                                                                href: item.external
                                                                                  ? item.optionLink
                                                                                  : _vm
                                                                                      .globals
                                                                                      .contextPath +
                                                                                    item.optionLink,
                                                                                target: item.external
                                                                                  ? "_blank"
                                                                                  : "_self"
                                                                              },
                                                                              domProps: {
                                                                                innerHTML: _vm._s(
                                                                                  item.option
                                                                                )
                                                                              },
                                                                              on: {
                                                                                keyup: function(
                                                                                  $event
                                                                                ) {
                                                                                  if (
                                                                                    !(
                                                                                      "button" in
                                                                                      $event
                                                                                    ) &&
                                                                                    _vm._k(
                                                                                      $event.keyCode,
                                                                                      "esc",
                                                                                      27,
                                                                                      $event.key,
                                                                                      "Escape"
                                                                                    )
                                                                                  ) {
                                                                                    return null
                                                                                  }
                                                                                  return _vm.hideDropDownMenu(
                                                                                    $event
                                                                                  )
                                                                                }
                                                                              }
                                                                            }
                                                                          )
                                                                        ]
                                                                      : _vm._e()
                                                                  ],
                                                                  2
                                                                )
                                                              }
                                                            )
                                                          )
                                                        ],
                                                        2
                                                      )
                                                    }
                                                  )
                                                ],
                                                2
                                              )
                                            : _vm._e(),
                                          _vm._v(" "),
                                          _vm.isSecondaryAvailable(item) &&
                                          _vm.selectedPrimary ===
                                            item.primary &&
                                            _vm.isTablet()
                                            ? _c(
                                                "div",
                                                {
                                                  staticClass:
                                                    "secondary-menu mobile pt-xs-3 px-md-4 pt-md-4 pb-md-2",
                                                  class: {
                                                    "secondary-active":
                                                      _vm.selectedPrimary ===
                                                      item.primary
                                                  }
                                                },
                                                [
                                                  _c("div", {
                                                    staticClass: "arrow-up"
                                                  }),
                                                  _vm._v(" "),
                                                  _vm.showMobileNav
                                                    ? _c("a", {
                                                        staticClass:
                                                          "icon-chevron-left ml-xs-3 px-xs-0 js-secondary-action-el",
                                                        attrs: {
                                                          tabindex: "0",
                                                          role: "button",
                                                          "aria-label": "Back",
                                                          href:
                                                            "javascript:void(0)"
                                                        },
                                                        on: {
                                                          click: function(
                                                            $event
                                                          ) {
                                                            $event.stopPropagation()
                                                            _vm.focusParent(
                                                              _vm.selectedPrimary,
                                                              _vm.navMenu
                                                            )
                                                          }
                                                        }
                                                      })
                                                    : _vm._e(),
                                                  _vm._v(" "),
                                                  _vm._l(
                                                    item.secondaryList,
                                                    function(secondaryItem) {
                                                      return _c(
                                                        "div",
                                                        {
                                                          staticClass:
                                                            "secondary-category",
                                                          attrs: {
                                                            tabindex: "0"
                                                          }
                                                        },
                                                        [
                                                          secondaryItem.title
                                                            ? [
                                                                _c("div", {
                                                                  staticClass:
                                                                    "pl-xs-3 sec-title",
                                                                  domProps: {
                                                                    innerHTML: _vm._s(
                                                                      secondaryItem.title
                                                                    )
                                                                  }
                                                                })
                                                              ]
                                                            : _vm._e(),
                                                          _vm._v(" "),
                                                          _c(
                                                            "ul",
                                                            {
                                                              staticClass:
                                                                "d-flex flex-wrap pl-xs-0 flyout-sec",
                                                              attrs: {
                                                                role: "list"
                                                              }
                                                            },
                                                            _vm._l(
                                                              secondaryItem.menuOptions,
                                                              function(item) {
                                                                return _c(
                                                                  "li",
                                                                  {
                                                                    staticClass:
                                                                      "js-secondary-child p-md-3",
                                                                    class: {
                                                                      "view-all":
                                                                        item.markAsViewAll
                                                                    },
                                                                    attrs: {
                                                                      role:
                                                                        "listitem"
                                                                    }
                                                                  },
                                                                  [
                                                                    item.option
                                                                      ? [
                                                                          _c(
                                                                            "a",
                                                                            {
                                                                              staticClass:
                                                                                "p-xs-3 p-md-0 custom-word-wrap-all",
                                                                              attrs: {
                                                                                href: item.external
                                                                                  ? item.optionLink
                                                                                  : _vm
                                                                                      .globals
                                                                                      .contextPath +
                                                                                    item.optionLink,
                                                                                target: item.external
                                                                                  ? "_blank"
                                                                                  : "_self"
                                                                              },
                                                                              domProps: {
                                                                                innerHTML: _vm._s(
                                                                                  item.option
                                                                                )
                                                                              }
                                                                            }
                                                                          )
                                                                        ]
                                                                      : _vm._e()
                                                                  ],
                                                                  2
                                                                )
                                                              }
                                                            )
                                                          )
                                                        ],
                                                        2
                                                      )
                                                    }
                                                  )
                                                ],
                                                2
                                              )
                                            : _vm._e()
                                        ],
                                        2
                                      )
                                    })
                                  )
                                ])
                              ]
                            )
                          ]
                        )
                      ]
                    )
                  : _vm._e()
              ],
              2
            ),
            _vm._v(" "),
            _vm.headerOptions.includeSearchBox && !_vm.isTablet()
              ? _c(
                  "div",
                  {
                    staticClass: "col-xs-3 mx-xs-5",
                    class: {
                      "align-search": !_vm.headerOptions.includeMiniCart
                    }
                  },
                  [
                    _vm._t(
                      "search",
                      [_vm._v("\n          Default body\n        ")],
                      { toggleSearchIcon: _vm.toggleSearchIcon }
                    )
                  ],
                  2
                )
              : _vm._e()
          ]
        )
      ],
      1
    ),
    _vm._v(" "),
    _c("div", { staticClass: "printable-section printable-image" }, [
      _vm.globals.siteConfig.isBrandLogoMobile && _vm.isTablet()
        ? _c("img", {
            attrs: {
              alt: _vm.globals.getSiteName(),
              src: _vm.globals.assetsPath + _vm.headerOptions.brandMobileLogo
            }
          })
        : _vm.unitHeaderLogo
        ? _c("img", {
            attrs: { alt: _vm.globals.getSiteName(), src: _vm.unitHeaderLogo }
          })
        : _c("img", {
            attrs: {
              alt: _vm.globals.getSiteName(),
              src: _vm.globals.assetsPath + _vm.headerOptions.brandLogo
            }
          })
    ])
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "ul",
      { staticClass: "m-xs-0 primary-menu align-self-center d-flex pl-xs-0" },
      [
        _c(
          "li",
          { staticClass: "primary-menu-item js-primary-menu-item p-md-3" },
          [
            _c("a", { staticClass: "p-xs-3 p-md-0", attrs: { href: "/" } }, [
              _vm._v("\n                      Home\n                    ")
            ])
          ]
        )
      ]
    )
  }
]
render._withStripped = true
export { render, staticRenderFns }
if (module.hot) {
  module.hot.accept()
  if (module.hot.data) {
    require("vue-hot-reload-api")      .rerender("data-v-7be2870e", { render: render, staticRenderFns: staticRenderFns })
  }
}