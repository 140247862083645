/* eslint-disable no-cond-assign */
/* eslint-disable no-param-reassign */
/* eslint-disable no-restricted-syntax */
import globals from '../../../common/globals';
import CommonService from '../../../common/services/common-service';
import vxAutoSuggest from '../../search-browse/vx-auto-suggest/vx-auto-suggest.vue';
import vxDropdownPrimary from '../../../common/vx-dropdown-primary/vx-dropdown-primary.vue';
import headerMixin from '../../../common/mixins/header-mixin';
import vxLeftNav from '../../../manage-profile-shopping-lists/vx-left-nav/vx-left-nav.vue';
import mobileMixin from '../../../common/mixins/mobile-mixin';
import vxSpinner from '../../../common/vx-spinner/vx-spinner.vue';
import cookiesMixin from '../../../common/mixins/cookies-mixin';
import { cookies } from '../../../common/mixins/vx-enums';

/**
 * Header section
 */
export default {
  name: 'vx-header-section',
  mixins: [headerMixin, mobileMixin, cookiesMixin],
  props: {
    // Header data including categories
    headerData: {
      type: Object,
      required: true,
    },
    // Text and label from properties file
    i18n: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      globals,
      showMobileNav: false,
      selectedPrimary: '',
      screenWidth: window.screen.width,
      desktopWidthMin: 1200,
      userName: '',
      tabletView: false,
      toggleSearchIcon: false,
      cookies,
      windowWidth: window.innerWidth,
      commonService: new CommonService(),
      unitHeaderLogo: '',
    };
  },
  computed: {
    // Configurations for header (includes flags)
    headerOptions() {
      return this.headerData.headerOptions;
    },
    // Categories data in header data
    navMenu() {
      return this.headerData.navMenu;
    },
    // Find store/distributor menu data
    findMenu() {
      return this.headerData.findMenu;
    },
  },
  components: {
    vxAutoSuggest,
    vxLeftNav,
    vxSpinner,
    vxDropdownPrimary,
  },
  methods: {
    /* eslint no-undef: "error" */

    /**
     * Dynamically sets the height for hamburger menu
     */
    setHeaderHeight() {
      if (this.showMobileNav) {
        setTimeout(() => {
          this.$el.querySelector(
            '.header-left-section .mobile-view',
          ).style.height = `${window.innerHeight - 52}px`;
        }, 400);
      }
    },
    /**
     * Closes the hamburger menu
     */
    dismissMobileMenu() {
      if (this.screenWidth <= this.desktopWidthMin) {
        this.showMobileNav = !this.showMobileNav;
      }
    },
    /**
     * Updates the accessibility related attributes
     * @param {boolean} isExpand Indicates if the hamburger is displayed or hidden
     */
    updateAria(isExpand) {
      if (isExpand) {
        if (document.querySelector('.navbar-bottom-right')) {
          document
            .querySelector('.navbar-bottom-right')
            .setAttribute('aria-hidden', true);
        }
        if (document.querySelector('.main__inner-wrapper')) {
          document
            .querySelector('.main__inner-wrapper')
            .setAttribute('aria-hidden', true);
        }
        if (document.querySelector('.footer-section')) {
          document
            .querySelector('.footer-section')
            .setAttribute('aria-hidden', true);
        }
      } else {
        if (document.querySelector('.navbar-bottom-right')) {
          document
            .querySelector('.navbar-bottom-right')
            .setAttribute('aria-hidden', false);
        }
        if (document.querySelector('.main__inner-wrapper')) {
          document
            .querySelector('.main__inner-wrapper')
            .setAttribute('aria-hidden', false);
        }
        if (document.querySelector('.footer-section')) {
          document
            .querySelector('.footer-section')
            .setAttribute('aria-hidden', false);
        }
      }
    },

    /**
     * Displays the secondary category menu and updates the accessibility related attributes for hamburger menu
     * @param {string} selectedItem Primary category item selected by the user
     */
    selectPrimary(selectedItem) {
      this.selectedPrimary = selectedItem;
      const self = this;
      if (this.screenWidth <= this.desktopWidthMin) {
        setTimeout(() => {
          self.$refs.navigationMenu.scrollTop = 0;
          document.querySelector('.js-secondary-action-el').focus();
        }, 150);
      }
      if (this.selectPrimary !== '') {
        if (document.querySelector('.navbar-bottom-right')) {
          document
            .querySelector('.navbar-bottom-right')
            .setAttribute('aria-hidden', true);
        }
        if (document.querySelector('.main__inner-wrapper')) {
          document
            .querySelector('.main__inner-wrapper')
            .setAttribute('aria-hidden', true);
        }
        if (document.querySelector('.footer-section')) {
          document
            .querySelector('.footer-section')
            .setAttribute('aria-hidden', true);
        }
      } else {
        if (document.querySelector('.navbar-bottom-right')) {
          document
            .querySelector('.navbar-bottom-right')
            .setAttribute('aria-hidden', false);
        }
        if (document.querySelector('.main__inner-wrapper')) {
          document
            .querySelector('.main__inner-wrapper')
            .setAttribute('aria-hidden', false);
        }
        if (document.querySelector('.footer-section')) {
          document
            .querySelector('.footer-section')
            .setAttribute('aria-hidden', false);
        }
      }
    },
    /**
     * Checks if secondary categories are available for a primary category and returns boolean
     * @param {string} primaryItem Primary category item selected by the user
     */
    isSecondaryAvailable(primaryItem) {
      return primaryItem.secondaryList && primaryItem.secondaryList.length > 0;
    },
    /**
     * Sets focus to primary category item in hamburger when returning from secondary menu
     * @param {string} el Primary category item
     * @param {object} menu Categories menu
     */
    focusParent(el, menu) {
      this.selectedPrimary = '';
      for (const i in menu) {
        if (menu[i].primary === el) {
          setTimeout(() => {
            document
              .querySelectorAll('.primary-menu-item')[i].childNodes[0].focus();
          }, 100);
        }
      }
    },
    /**
     * The below method is used to get the parent
     * element with specific class
     * @param {*the current element} el
     * @param {*the target class } cls
     */
    findAncestor(el, cls) {
      while ((el = el.parentElement) && !el.classList.contains(cls));
      return el;
    },

    returnItemsImages(items) {
      const images = [];
      for (let i = 0; i < items.length; i += 1) {
        if (items[i].imageData) {
          const { imageData } = items[i];
          imageData.link = items[i].ctaLink;
          imageData.isExternalLink = items[i].external;
          images.push(imageData);
        }
      }
      return images;
    },
    /**
     * Handles the window resize
     */
    handleResize() {
      if (window.innerWidth !== this.windowWidth) {
        this.tabletView = this.isTablet();
        this.windowWidth = window.innerWidth;
      }
    },
    /**
     * Sets the height of secondary menu container (only for desktop)
     * @param {object} event Mouseover event
     * @param {number} count Number of secondary menu items for the hovered primary menu item
     */
    setHeaderFlyoutWidth(event, count) {
      const targetedElement = event.target;
      const oneColumnWidth = 323;
      const styleProp = targetedElement
        .querySelector('.secondary-menu')
        .getAttribute('style');
      const liList = targetedElement.querySelectorAll('.flyout-sec li');
      let liWidth = oneColumnWidth;
      let maxLiWidth = oneColumnWidth;

      if (styleProp) {
        return;
      }
      liList.each((listElement) => {
        const elementWidth = listElement.offsetWidth;

        if (liWidth < elementWidth && maxLiWidth < elementWidth) {
          liWidth = elementWidth;
          maxLiWidth = elementWidth;
        }
      });

      if (count > 10 && count < 16) {
        targetedElement.querySelector('.secondary-menu')
          .style.width = `${liWidth * 3}px`;
      } else if (count > 15) {
        targetedElement.querySelector('.secondary-menu')
          .style.width = `${liWidth * 4}px`;
      } else if (count < 6) {
        targetedElement.querySelector('.secondary-menu')
          .style.width = `${liWidth}px`;
        targetedElement.querySelector('.js-secondary-child')
          .style.maxWidth = '100%';
      } else {
        targetedElement.querySelector('.secondary-menu')
          .style.width = `${liWidth * 2}px`;
      }
    },
    /* Method to hide dropdown menu if user hits escape key */
    hideDropDownMenu() {
      const secondaryMenu = this.$el.querySelectorAll('.secondary-menu');
      for (const element of secondaryMenu) {
        element.style.visibility = 'hidden';
      }
    },
    /* Method to show dropdown menu when user hovers or tabs over primary navigation links */
    displayDropDownMenu() {
      const secondaryMenu = this.$el.querySelectorAll('.secondary-menu');
      for (const element of secondaryMenu) {
        element.style.visibility = 'visible';
      }
    },
  },
  async mounted() {
    this.clearKauthData();
    this.tabletView = this.isTablet();

    window.addEventListener('resize', this.handleResize);
    this.handleResize();
  },
  destroyed() {
    window.removeEventListener('resize', this.handleResize);
  },
  filters: {
    maxCharacters(value) {
      const charLimit = 120;
      return value.substring(0, charLimit);
    },
    lowercase(value) {
      return value.replace(/\W+/g, '-').toLowerCase();
    },
  },
};
