<template>
  <section>
    <vx-tab-container-render :i18n="$root.messages.pdpTabContainer" :is-favorites="isFavorites"
      :is-bazaar-voice="isBazaarVoice" :is-related-enabled="isRelatedEnabled"
      :search-browse-i18n="$root.messages.searchBrowse.productTile" :product-id="productId"></vx-tab-container-render>
  </section>
</template>
<script>
export default {
  name: 'vx-tab-container',
  components: {
    vxTabContainerRender: () => import(/* webpackChunkName: "vx-tab-container" */ './vx-tab-container.vue'),
  },
  props: {
    // Copy text coming from properties files
    i18n: {
      type: Object,
    },
    // indicates whether the site is configured for favorites
    isFavorites: {
      type: Boolean,
      default: false,
    },
    // indicates whether the site is configured for Bazaar Voice
    isBazaarVoice: {
      type: String,
      default: '',
    },
    // indicates whether the related products are enabled for the product
    isRelatedEnabled: {
      type: Boolean,
      default: false,
    },
    // Copy text coming from properties files for search browse components
    searchBrowseI18n: {
      type: Object,
    },
    productId: {
      type: String,
      required: true,
    },
    productID: {
      type: String,
      required: true,
    },
  },
};
</script>
