/**
 *  This component is the vertical view of product details
 */
import globals from '../../../common/globals';
import flyoutBannerMixin from '../../../common/vx-flyout-banner/vx-flyout-banner-mixin';
import {
  ProductAvailability,
  favorites,
} from '../../../common/mixins/vx-enums';
import mobileMixin from '../../../common/mixins/mobile-mixin';
import cookiesMixin from '../../../common/mixins/cookies-mixin';
import AnalyticsService from '../../../common/services/analytics-service';
import SearchBrowseService from '../../../common/services/search-browse-service';
import vxSpinner from '../../../common/vx-spinner/vx-spinner.vue';
import vxStarRating from '../../../common/vx-star-rating/vx-star-rating.vue';

export default {
  data() {
    return {
      ProductAvailability,
      favorites,
      disableCheckbox: false,
      isHidden: true,
      pdpContextPath: '',
      globals,
      ratingCal: '',
      isActive: '',
      analyticsService: new AnalyticsService(),
      searchBrowseService: new SearchBrowseService(),
      characterLimit: 150,
      isTruncatedName: false,
      showRating: true,
      showReviews: true,
    };
  },
  props: {
    // Copy text coming from properties file
    i18n: {
      type: Object,
    },
    // Copy text coming from properties file
    product: {
      type: Object,
    },
    // indicates whether the product is unchecked
    uncheckProduct: {
      type: Boolean,
      default: false,
    },
    // indicates whether to show remove icon
    showRemoveOption: {
      type: Boolean,
      default: false,
    },
    // indicates whether to show compare checkbox
    showCompareOption: {
      type: Boolean,
      default: false,
    },
    // indicates whether to show select checkbox
    showSelectOption: {
      type: Boolean,
      default: false,
    },
    // indicates whether to show favorite icon
    isFavoriteVisible: {
      type: Boolean,
      default: false,
    },
    // indicates whether to show product indicators
    showProductIndicators: {
      type: Boolean,
      default: false,
    },
    // indicates whether to hide item id
    isItemIdHide: {
      type: Boolean,
      default: false,
    },
    // indicates whether refill is enabled
    isRefill: {
      type: Boolean,
      default: false,
    },
    // indicates whether the site is configured for Bazaar Voice
    isBazaarVoice: {
      type: String,
      default: '',
    },
    // indicates whether the site is configured for bulk
    isBulkEnabled: {
      type: Boolean,
      default: false,
    },
    // string which states bundle product status
    bundleProductStatus: {
      type: String,
      default: '',
    },
    // indicates whether the bundles checkbox is disabled
    isBundlesCheckboxDisabled: {
      type: Boolean,
      default: false,
    },
    // indicates whether to hide button block
    hideButtonBlock: {
      type: Boolean,
      default: false,
    },
    // indicates whether its the bundles tile
    isBundlesTile: {
      type: Boolean,
      default: false,
    },
  },
  mixins: [cookiesMixin, flyoutBannerMixin, mobileMixin],
  name: 'vx-product-tile-vertical',
  components: {
    vxSpinner,
    vxStarRating,
  },
  methods: {
    /**
     * Function trigerred on click of Add To Cart button
     */
    calculateRating(rating) {
      this.ratingCal = Math.floor(rating);
      if (rating - this.ratingCal > 0) {
        this.isHalfFilled = true;
      }
    },
    /**
     * Function to format product name
     * @param  {String} productName string of product name
     * @param  {Number} characterLimit number of character limit
     */
    formatProductName(productName, characterLimit) {
      if (productName) {
        const formattedName = productName.replace(/<[^>]*>/g, '');
        if (formattedName.length > characterLimit) {
          this.isTruncatedName = true;
          return `${formattedName.substr(0, characterLimit)}...`;
        }
        return formattedName;
      }
      return undefined;
    },
    /**
     * Function to get product name
     * @param  {String} productName string of product name
     */
    getProductName(productName) {
      const fullName = productName.replace(/<[^>]*>/g, '');
      return fullName;
    },
    findAStore() {
      let findStorePage = '';
      const self = this;
      const nameArr = self.product.name.split(' ');
      const productColor = nameArr[nameArr.length - 1].toLowerCase();

      const sendTracking = function(category, callback) {
        window.ga.getAll()[0].send('event', {
          eventCategory: 'External Links',
          eventAction: 'Click',
          eventLabel: `${category}-${productColor}`,
        });
        if (callback) {
          callback.call();
        }
      };
      const navigateToAmazon = function(url) {
        window.open(url, '_blank');
      };

      const setAmazonURL = function() {
        switch (self.globals.siteId) {
          case 'aria': {
            findStorePage = 'https://www.amazon.com/dp/B0711XJ9BR/ref=emc_b_5_t';
            window.open(findStorePage, '_blank');
            break;
          }
          case 'innovia': {
            if (self.product.url.toLowerCase().replace(/[^a-zA-Z ]/g, '').indexOf('undercabinet') > -1) {
              findStorePage = 'https://www.amazon.com/stores/page/97F97E45-D73D-4EF1-8600-1EFF630D1B19?ingress=2&visitId=2e248e7b-ee84-46ca-89cc-71973381a715&ref_=ast_bln';
              sendTracking('undercabinet', navigateToAmazon(findStorePage));
            } else if (self.product.url.toLowerCase().replace(/[^a-zA-Z ]/g, '').indexOf('countertop') > -1) {
              findStorePage = 'https://www.amazon.com/stores/page/EAF878A9-F3CA-4EBA-84C3-D4DF24907A9B?ingress=2&visitId=d9e03777-b01b-498d-954c-6efd1986f281&ref_=ast_bln';
              sendTracking('countertop', navigateToAmazon(findStorePage));
            } else {
              findStorePage = 'https://www.amazon.com/stores/node/17978508011?_encoding=UTF8&field-lbr_brands_browse-bin=Innovia&ref_=bl_dp_s_web_17978508011';
              sendTracking('', navigateToAmazon(findStorePage));
            }
            break;
          }
          default: {
            findStorePage = self.globals.getNavBaseUrl() + self.globals.serviceUrls.locateStore;
            window.location.href = findStorePage;
            break;
          }
        }
      };
      setAmazonURL();
    },
    /**
     * Function triggered on click of select a list icon
     */
  },
  async mounted() {
    this.calculateRating(this.product.bvAverageRating);
    if (this.product.url.startsWith('/GP-Family-of-Brands')) {
      this.product.url = this.product.url.replace('/GP-Family-of-Brands', '');
    }
  },
};
