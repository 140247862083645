<template>
  <section>
    <vx-rich-text-render :rich-text-markup="richTextMarkup">
    </vx-rich-text-render>
  </section>
</template>
<script>
export default {
  name: 'vx-rich-text',
  components: { vxRichTextRender: () => import(/* webpackChunkName: "vx-rich-text" */ './vx-rich-text.vue') },
  props: {
    richTextMarkup: {
      type: String,
      required: true,
    },
  },
};
</script>
