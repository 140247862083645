/**
 * Auto Suggest component for loading matching products on search
 * This component comes up when user enters the 3rd character in the input field in the header
 */
import debounce from 'lodash.debounce';
import cookiesMixin from '../../../common/mixins/cookies-mixin';
import mobileMixin from '../../../common/mixins/mobile-mixin';
import vxProductTileVertical from '../vx-product-tile-vertical/vx-product-tile-vertical.vue';
import vxDropdownPrimary from '../../../common/vx-dropdown-primary/vx-dropdown-primary.vue';
import globals from '../../../common/globals';
import SearchBrowseService from '../../../common/services/search-browse-service';
import detectDeviceMixin from '../../../common/mixins/detect-device-mixin';

export default {
  name: 'vx-auto-suggest',
  mixins: [cookiesMixin, detectDeviceMixin, mobileMixin],
  components: {
    vxProductTileVertical,
    vxDropdownPrimary,
  },
  props: {
    /**
     * product catalog for respective categories
     */
    productCatalog: {
      type: String,
      default: '',
    },
    /**
     * root category for product
     */
    rootCategory: {
      type: String,
      default: '',
    },
    /**
     * indicates whether the site is configured for favorites
     */
    isFavorites: {
      type: Boolean,
      default: false,
    },
    /**
     * indicates whether to search icon or not
     */
    toggleSearchIcon: {
      type: Object,
    },
    /**
     * indicates whether the site is configured for Bazaar Voice
     */
    isBazaarVoice: {
      type: String,
      default: '',
    },
    /**
     * Copy text coming from properties files for search browse components
     */
    searchBrowseI18n: {
      type: Object,
    },
    /**
     * indicates whether the site is configured for single product view
     */
    singleProductEnabled: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    productList() {
      return this.autosuggestPanel;
    },
  },
  data() {
    return {
      i18n: this.searchBrowseI18n.autoSuggest,
      categories: {},
      myValue: '',
      showAutosuggestPanel: false,
      autosuggestPanel: [],
      searchTerm: '',
      globals,
      isEnterPressed: false,
      isB2B: false,
      clearTextIcon: false,
      categoryValues: [],
      currentCategoryValue: '',
      currentCategoryName: '',
      categoryUrl: '',
      categoryName: '',
      searchIconDisabled: false,
      lastSelectedcategory: '',
      isPdpRedirect: false,
      delayInterval: 500,
      searchBrowseService: new SearchBrowseService(),
    };
  },
  created() {
    const self = this;
    window.addEventListener('click', () => {
      this.showAutosuggestPanel = false;
    });
    self.autosuggest = debounce((inp) => {
      if (self.toggleSearchIcon) {
        self.disableSearchIcon();
      }
      self.clearTextIcon = true;
      const e = inp;
      if (this.globals.isB2B()) {
        if (self.searchTerm.length <= 1) {
          self.onRemoveProduct();
          return false;
        }
      } else {
        // eslint-disable-next-line no-lonely-if
        if (self.searchTerm.length <= 2) {
          self.onRemoveProduct();
          return false;
        }
      }
      self.autosuggestPanel = [];
      self.showAutosuggestPanel = true;
      const trimmedSearchTerm = self.searchTerm.trim();
      const encodedSearchTerm = encodeURIComponent(trimmedSearchTerm);
      // commented for R1 release
      // if (self.globals.isB2B()) {
      //   if (self.categoryName) {
      //     encodedSearchTerm += self.categoryName;
      //   }
      // }
      if (e.which === 13) {
        self.isEnterPressed = true;
        self.isPdpRedirect = true;
      }
      const requestConfig = {};
      if (this.globals.loggedIn) {
        const userId = this.globals.uid;
        requestConfig.params = {
          term: encodedSearchTerm,
          maxProducts: 4,
          fields: 'FULL',
          userId,
        };
      } else {
        requestConfig.params = {
          term: encodedSearchTerm,
          maxProducts: 4,
          fields: 'FULL',
        };
      }
      this.searchBrowseService.getAutosuggest(
        requestConfig,
        this.handleSuggestResponse,
        this.handleSuggestError,
      );
      return undefined;
    }, self.delayInterval);
  },
  async mounted() {
    this.onInit();
    const vh = window.innerHeight * 0.01;
    document.documentElement.style.setProperty('--vh', `${vh}px`);
  },
  methods: {
    /**
     * This function is called on mounted which gets the product categories
     */
    onInit() {
    },
    /**
     * This function is called on click of View All link in Autosuggest
     */
    viewAllClicked() {
      this.isEnterPressed = true;
      const requestConfig = {};
      const trimmedSearchTerm = this.searchTerm.trim();
      const encodedSearchTerm = encodeURIComponent(trimmedSearchTerm);
      if (this.globals.loggedIn) {
        const userId = this.globals.uid;
        requestConfig.params = {
          term: encodedSearchTerm,
          maxProducts: 4,
          fields: 'FULL',
          userId,
        };
      } else {
        requestConfig.params = {
          term: encodedSearchTerm,
          maxProducts: 4,
          fields: 'FULL',
        };
      }
      this.searchBrowseService.getAutosuggest(
        requestConfig,
        this.handleSuggestResponse,
        this.handleSuggestError,
      );
    },
    /**
     * This function sorts data for the category dropdown
     */
    createCategoriesArray() {
      const self = this;
      self.lastCategory = this.readCookie('categoryVal');
      this.categoryValues = [
        {
          label: 'All',
          value: '',
          url: '',
        },
      ];
      this.categories.subcategories.forEach((item) => {
        this.categoryValues.push({
          label: item.name,
          value: item.id,
          url: item.url,
        });
      });
      this.setCategoryDropdown(self.lastCategory);
    },
    /**
     * This function sets the category dropdown
     * @param  {String} lastCategory string of category retrieved from the categoryVal cookie
     */
    setCategoryDropdown(lastCategory) {
      let lastSelectedcategory = {};
      if (!lastCategory) {
        this.currentCategoryName = this.categoryValues[0].label;
      } else {
        lastSelectedcategory = this.categoryValues.filter(
          (category) => category.value === lastCategory,
        )[0];
        if (!lastSelectedcategory) {
          this.currentCategoryName = this.categoryValues[0].label;
        } else {
          this.getSelectedDropdown(lastSelectedcategory);
          this.eraseCookie('categoryVal');
        }
      }
      if (this.globals.isB2B()) {
        this.$refs.category.setDropdownLabel(this.currentCategoryName);
      }
    },
    /**
     * This acts as a placeholder for autosuggest function which will trigger autosuggest defined in created()
     */
    autosuggest() {},
    /**
     * This function is triggered when the length of characters in the inout field is less than 2
     */
    onRemoveProduct() {
      this.autosuggestPanel.splice(0, this.autosuggestPanel.length);
      if (this.searchTerm.length === 0) {
        this.clearTextIcon = false;
        if (this.toggleSearchIcon) {
          this.disableSearchIcon();
        }
      }
    },
    /**
     * This function handles the autosuggest service call response
     */
    handleSuggestResponse(response) {
      const self = this;
      let url = '';
      if (this.isEnterPressed) {
        // enter key
        // Storing the value for category dropdown in B2B
        self.createCookie('categoryVal', this.currentCategoryValue);
        self.eraseCookie('ProductCompare');
        if (response.data.pdpRedirectURL && self.isPdpRedirect) {
          // Navigate to PDP
          url += `${this.globals.getNavBaseUrl()}/`;
          url += `${this.globals.locale + response.data.pdpRedirectURL}?site=${
            this.globals.siteId
          }`;
          window.location = url;
        } else {
          const trimmedSearchTerm = this.searchTerm.trim();
          const encodedSearchTerm = encodeURIComponent(trimmedSearchTerm);
          // commented for R1 release
          // if (this.globals.isB2B()) {
          //   const queryParam = encodedSearchTerm + this.categoryName;
          //   this.globals.navigateToUrlWithParams('searchResults', queryParam, 'query');
          // } else {
          this.globals.navigateToUrlWithParams(
            'searchResults',
            encodedSearchTerm,
            'query',
          );
          // }
        }
      } else {
        this.autosuggestPanel = response.data.products;
      }
    },
    /* handleCategoryResponse(response) {
      this.categories = response.data;
      this.createCategoriesArray();
    },
    handleCategoryError(error) {}, */
    // handle B2B category dropdown
    /**
     * This function sets the category dropdown
     * @param  {String} categoryVal string of category retrieved from the categoryVal cookie
     */
    getSelectedDropdown(categoryVal) {
      this.currentCategoryValue = categoryVal.value;
      this.currentCategoryName = categoryVal.label;
      if (!this.currentCategoryValue) {
        this.categoryName = '';
      } else {
        this.categoryName = encodeURIComponent(
          `:relevance:allCategories:${this.currentCategoryValue}:`,
        );
      }
    },
    /**
     * This function hides the autosuggest panel
     */
    clearSearch() {
      this.showAutosuggestPanel = false;
      this.searchTerm = '';
      this.clearTextIcon = false;
      if (this.toggleSearchIcon) {
        this.disableSearchIcon();
      }
    },
    /**
     * This function toggles the search icon in the auto suggest
     */
    disableSearchIcon() {
      if (this.searchTerm.length !== 0) {
        this.searchIconDisabled = true;
      } else {
        this.searchIconDisabled = false;
      }
    },
    /**
     * This function handles the autosuggest service call error
     */
    handleSuggestError() {},
  },
};
