import vxLiveChat from '../components/b2c/access-customer-service/vx-live-chat/vx-live-chat-shell';
import vxContactUs from '../components/b2c/access-customer-service/vx-contact-us/vx-contact-us-shell';

const accessCustomerServiceModule = {
  components: {
    vxLiveChat,
    vxContactUs,
  },
};

export default accessCustomerServiceModule;
