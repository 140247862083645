import common from '../b2c/common-i18n';
import viewSiteContent from '../b2c/view-site-content-i18n';
import accessCustomerService from '../b2c/access-customer-service-i18n';
import nfr from '../b2c/nfr-i18n';
import searchBrowse from '../b2c/search-browse-i18n';

const messages = {
  'en-US': {
    accessCustomerService,
    // This below pdp section should come under one pdp.i18n
    pdpProductInfo: {
      findAStore: 'Where To Buy',
      findAStoreLabel: '',
      asmHeading: 'ASM Alert',
      asmMessage: 'Please click Start Session button to start guest session.',
      moreImages: 'More Images',
      iconMaximizeTitle: 'Zoom In'
    },
    pdpTabContainer: {
      tabDetails: 'Details',
      tabReviews: 'Reviews',
      tabRelatedProducts: 'Related Products',
    },
    pdp: {
      productDetails: {
        topLeftHeading: 'Product Overview',
        topRightHeading: 'Product Features',
        bottomHeading: 'Product Specifications',
      },
      relatedProductsTab: {
        topLeftHeading: 'Related Products',
      },
    },
    common,
    viewSiteContent,
    nfr,
    searchBrowse,
  },
};

export default messages;
