<template>
  <section>
    <vx-product-support-render :tabs-data='tabsData' :component-title='componentTitle' :component-id='componentId'
      :component-theme='componentTheme' :active-tab='activeTab'>
    </vx-product-support-render>
  </section>
</template>
<script>
export default {
  name: 'vx-product-support',
  components: { vxProductSupportRender: () => import(/* webpackChunkName: "vx-product-support" */ './vx-product-support.vue') },
  props: {
    tabsData: {
      type: Array,
      required: true,
    },
    componentTitle: {
      type: String,
      required: true,
    },
    componentId: {
      type: String,
    },
    componentTheme: {
      type: String,
    },
    activeTab: {
      type: Number,
      default: 0,
    },
  },
};
</script>
