<template>
  <section>
<vx-bazaar-voice-reviews :config='config'></vx-bazaar-voice-reviews>
  </section>
</template>
<script>
export default {
  name: 'vx-bazaar-voice-reviews-render',
  components: { vxBazaarVoiceReviews: () => import(/* webpackChunkName: "vx-bazaar-voice-reviews" */ './vx-bazaar-voice-reviews.vue') },
  props: {
    config: {
      type: Object,
      required: false,
    },
  },
};
</script>
