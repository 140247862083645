<template>
  <section>

  <vx-interactive-map-render>
  </vx-interactive-map-render></section></template>
<script>
export default {
  name: 'vx-interactive-map',
  components: {
    vxInteractiveMapRender: () => import(/* webpackChunkName: "vx-interactive-map" */ './vx-interactive-map.vue'),
  },
};
</script>
