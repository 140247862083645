var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "section",
    [
      _c("vx-product-support-render", {
        attrs: {
          "tabs-data": _vm.tabsData,
          "component-title": _vm.componentTitle,
          "component-id": _vm.componentId,
          "component-theme": _vm.componentTheme,
          "active-tab": _vm.activeTab
        }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true
export { render, staticRenderFns }
if (module.hot) {
  module.hot.accept()
  if (module.hot.data) {
    require("vue-hot-reload-api")      .rerender("data-v-5051eebe", { render: render, staticRenderFns: staticRenderFns })
  }
}