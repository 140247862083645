<template>
  <section>
    <vx-bundles-render :bundles-data='bundlesData'>
    </vx-bundles-render>
  </section>
</template>
<script>
export default {
  name: 'vx-bundles',
  components: {
    vxBundlesRender: () => import(/* webpackChunkName: "vx-bundles" */ './vx-bundles'),
  },
  props: {
    // bundles data coming from parent component
    bundlesData: {
      type: Array,
      required: true,
    },
  },
};
</script>
