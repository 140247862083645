var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "section",
    [
      _c("vx-tab-container-render", {
        attrs: {
          i18n: _vm.$root.messages.pdpTabContainer,
          "is-favorites": _vm.isFavorites,
          "is-bazaar-voice": _vm.isBazaarVoice,
          "is-related-enabled": _vm.isRelatedEnabled,
          "search-browse-i18n": _vm.$root.messages.searchBrowse.productTile,
          "product-id": _vm.productId
        }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true
export { render, staticRenderFns }
if (module.hot) {
  module.hot.accept()
  if (module.hot.data) {
    require("vue-hot-reload-api")      .rerender("data-v-a0d654ba", { render: render, staticRenderFns: staticRenderFns })
  }
}