<template>
  <section>
    <vx-store-locator-render :i18n='$root.messages.viewSiteContent.storeLocator'>
    </vx-store-locator-render>
  </section>
</template>
<script>
export default {
  name: 'vx-store-locator',
  components: {
    vxStoreLocatorRender: () => import(/* webpackChunkName: "vx-store-locator" */ './vx-store-locator.vue'),
  },
  props: {
    i18n: {
      type: Object,
      required: true,
    },
  },
};
</script>
