/**
  vx-marketing-form is used for displaying content inside forms in smart edit
*/
<template>
  <section>
    <vx-marketing-form-new-render><slot></slot></vx-marketing-form-new-render>
  </section>
</template>
<script>
export default {
  name: 'vx-marketing-form-new',
  components: { vxMarketingFormNewRender: () => import(/* webpackChunkName: "vx-marketing-form-new" */ './vx-marketing-form-new.vue') },
};
</script>
