import vxBannerCarousel from '../components/view-site-content/vx-banner-carousel/vx-banner-carousel.vue';
import VxAccordionGroup from '../components/view-site-content/vx-faq/vx-faq.vue';
import vxHeroBanner from '../components/view-site-content/vx-hero-banner/vx-hero-banner.vue';
import vxMarketingPromo from '../components/view-site-content/vx-marketing-promo/vx-marketing-promo-shell';
import VxStoreLocator from '../components/view-site-content/vx-store-locator/vx-store-locator-shell';
import vxPriceSpider from '../components/view-site-content/vx-price-spider/vx-price-spider-shell';
import vxInteractiveMap from '../quiltednorthern/components/vx-interactive-map/vx-interactive-map-shell';
import VxImageTitle from '../components/view-site-content/vx-image-title/vx-image-title-shell';
import vxProductSupport from '../components/view-site-content/vx-product-support/vx-product-support-shell';
import vxProductSolution from '../components/view-site-content/vx-product-category-solution/vx-product-category-solution.vue';

import vxBrandBar from '../components/view-site-content/vx-brand-bar/vx-brand-bar-shell';
import vxSbs from '../components/view-site-content/vx-side-by-side/vx-side-by-side.vue';
import vxImageText from '../components/view-site-content/vx-image-text/vx-image-text.vue';
import vxFeatureProduct from '../components/view-site-content/vx-feature-product/vx-feature-product.vue';
import vxProductCarousel from '../components/view-site-content/vx-product-carousel/vx-product-carousel-shell';
import vxBvProductpicker from '../components/view-site-content/vx-bv-productpicker/vx-bv-productpicker-shell';

const viewSiteContentModule = {
  components: {
    vxHeroBanner,
    vxBannerCarousel,
    VxAccordionGroup,
    vxMarketingPromo,
    vxProductSolution,
    vxProductSupport,
    VxStoreLocator,
    vxPriceSpider,
    VxImageTitle,
    vxBrandBar,
    vxSbs,
    vxImageText,
    vxFeatureProduct,
    vxProductCarousel,
    vxBvProductpicker,
    vxInteractiveMap,
  },
};

export default viewSiteContentModule;
