var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "section",
    [
      _c("vx-pdp-product-info-render", {
        attrs: {
          i18n: _vm.$root.messages.pdpProductInfo,
          "product-id": _vm.productId,
          "is-favorites": _vm.isFavorites,
          "is-social-share": _vm.isSocialShare,
          "is-bazaar-voice": _vm.isBazaarVoice,
          "show-download": _vm.showDownload,
          "color-codes": _vm.colorCodes,
          "search-browse-i18n": _vm.$root.messages.searchBrowse.productTile,
          "show-custom-tabs": _vm.showCustomTabs
        }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true
export { render, staticRenderFns }
if (module.hot) {
  module.hot.accept()
  if (module.hot.data) {
    require("vue-hot-reload-api")      .rerender("data-v-70267d96", { render: render, staticRenderFns: staticRenderFns })
  }
}