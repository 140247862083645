import vxApplicationAuth from '../components/common/vx-application-auth/vx-application-auth.vue';
import vxHeaderSection from '../components/b2c/common/vx-header-section/vx-header-section.vue';
import vxFlyoutBanner from '../components/common/vx-flyout-banner/vx-flyout-banner.vue';
import vxFooterSection from '../components/common/vx-footer-section/vx-footer-section.vue';
import VxSectionTitle from '../components/common/vx-title/vx-title.vue';
import vxRichText from '../components/common/vx-rich-text/vx-rich-text-shell';
import vxJuicerFeed from '../components/common/vx-juicer-feed/vx-juicer-feed-shell';
import vxBreadcrumb from '../components/common/vx-breadcrumb/vx-breadcrumb-shell';
import vxCustomerDetails from '../components/common/vx-customer-details/vx-customer-details.vue';
import vxAssistiveText from '../components/common/vx-assistive-text/vx-assistive-text.vue';
import vxBundles from '../components/common/vx-bundles/vx-bundles-shell';

import vxMinimizedHeader from '../components/common/vx-minimized-header/vx-minimized-header.vue';
import vxBazaarVoiceReviews from '../components/common/vx-bazaar-voice-reviews/vx-bazaar-voice-reviews-shell';
import vxFlyoutBannerSecondary from '../components/common/vx-flyout-banner-secondary/vx-flyout-banner-secondary.vue';
import vxMarketingForm from '../components/common/vx-marketing-form/vx-marketing-form.vue';
import vxMarketingFormNew from '../components/common/vx-marketing-form/vx-marketing-form-new-shell';
import vxMarketingFormSms from '../components/common/vx-marketing-form/vx-marketing-form-sms-shell';
import initMixin from '../components/common/mixins/init-mixin';
import trackJSMixin from '../components/common/mixins/trackjs-mixin';
import vxEmbeddedChat from '../components/common/vx-embedded-chat/vx-embedded-chat.vue';

const commonModule = {
  components: {
    vxApplicationAuth,
    vxHeaderSection,
    vxFlyoutBanner,
    vxFooterSection,
    VxSectionTitle,
    vxRichText,
    vxJuicerFeed,
    vxBreadcrumb,
    vxCustomerDetails,
    vxAssistiveText,
    vxBundles,
    vxMinimizedHeader,
    vxBazaarVoiceReviews,
    vxFlyoutBannerSecondary,
    vxMarketingForm,
    vxMarketingFormNew,
    vxMarketingFormSms,
    vxEmbeddedChat,
  },
  mixins: [
    initMixin,
    trackJSMixin,
  ],

};

export default commonModule;
