<template>
  <section>
    <vx-thumbnail-viewer-render :i18n="$root.messages.pdpProductInfo">
    </vx-thumbnail-viewer-render>
  </section>
</template>
<script>
export default {
  name: 'vx-thumbnail-viewer',
  props: {
    // Copy text coming from properties files
    i18n: {
      type: Object,
    },
  },
  components: {
    vxThumbnailViewerRender: () => import(/* webpackChunkName: "vx-thumbnail-viewer" */ './vx-thumbnail-viewer.vue'),
  },
};
</script>
