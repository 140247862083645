import vxAutoSuggest from '../components/b2c/search-browse/vx-auto-suggest/vx-auto-suggest.vue';
import vxSearchResult from '../components/b2c/search-browse/vx-search-result/vx-search-result-shell.vue';
import vxCategoryDesc from '../components/common/vx-category-desc/vx-category-desc.vue';

const searchBrowseModule = {
  components: {
    vxAutoSuggest,
    vxSearchResult,
    vxCategoryDesc,
  },
};

export default searchBrowseModule;
