<template>
  <section>
    <vx-image-title-render :img-title-data='imgTitleData'>
    </vx-image-title-render>
  </section>
</template>
<script>
export default {
  name: 'vx-image-title',
  components: {
    vxImageTitleRender: () => import(/* webpackChunkName: "vx-image-title" */ './vx-image-title.vue'),
  },
  props: {
    imgTitleData: {
      type: Object,
      required: true,
      default: {},
    },
    jumpToHtml: {
      type: Boolean,
      default: true,
    },
  },
};
</script>
