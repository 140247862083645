<template>
  <section>
    <vx-marketing-promo-render class="col-sm-12" :promotion-data='promotionData'>
    </vx-marketing-promo-render>
  </section>
</template>
<script>
export default {
  name: 'vx-marketing-promo',
  components: { vxMarketingPromoRender: () => import(/* webpackChunkName: "vx-marketing-promo" */ './vx-marketing-promo.vue') },
  props: {
    promotionData: {
      type: Object,
      required: true,
      default: {},
    },
  },
};
</script>
