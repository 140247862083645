/* eslint-disable no-undef */
/* eslint-disable camelcase */
// The Vue build version to load with the `import` command
// (runtime-only or standalone) has been set in webpack.base.conf with an alias.
import 'promise-polyfill/src/polyfill';
import Vue from 'vue';
import VueLazyload from 'vue-lazyload';
import globals from '../components/common/globals';
import messages from '../locale/innovia/messages';
import commonModule from '../modules/common-b2c';
import searchBrowseModule from '../modules/search-browse-b2c';
import viewSiteContentModule from '../modules/view-site-content-b2c';
import pdpModule from '../modules/pdp-b2c';
import accessCustomerServiceModule from '../modules/access-customer-service-b2c';
import nfrModule from '../modules/nfr';
import '../components/common/directives/directives';
import variables from './variables';

const scriptURL = document.currentScript.src;
const parts = scriptURL.split('/');
__webpack_public_path__ = `${parts.slice(0, parts.length - 3).join('/')}/`;

Vue.use(VueLazyload, {
  lazyComponent: true,
  attempt: 1,
  preLoad: 1,
});
Vue.config.productionTip = false;
Vue.config.keyCodes = {
  // Enter or space Capturing
  'enter-space': [13, 32],
};
const vm = new Vue({
  el: '#innovia',
  data: {
    messages: messages['en-US'],
    globals,
    variables,
    cartExists: true,
  },
  mixins: [
    commonModule,
    searchBrowseModule,
    viewSiteContentModule,
    pdpModule,
    accessCustomerServiceModule,
    nfrModule,
  ],
  components: {},
  mounted() {
    /**
     * setting site specific variables
     */
    this.globals.siteVariables = this.variables;
  },
});

window.vm = vm;
Vue.config.errorHandler = (err, vm1, info) => {
  vm.sendToTrackJS(err, vm1, info);
};
