<template>
    <vx-bv-productpicker-render :config="config">
            <slot></slot>
    </vx-bv-productpicker-render>
</template>
<script>
export default {
  name: 'vx-bv-productpicker',
  props: {
    i18n: {
      type: Object,
      required: false,
    },
    config: {
      type: Object,
      required: false,
    },
  },
  components: {
    vxBvProductpickerRender: () => import(/* webpackChunkName: "vx-bv-productpicker" */ './vx-bv-productpicker.vue'),
  },
};
</script>
