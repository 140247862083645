<template>
  <section>
    <vx-pdp-product-info-render :i18n="$root.messages.pdpProductInfo" :product-id="productId"
      :is-favorites="isFavorites" :is-social-share="isSocialShare"
      :is-bazaar-voice="isBazaarVoice" :show-download="showDownload" :color-codes="colorCodes"
      :search-browse-i18n="$root.messages.searchBrowse.productTile" :show-custom-tabs="showCustomTabs">
    </vx-pdp-product-info-render>
  </section>
</template>
<script>
export default {
  name: 'vx-pdp-product-info',
  components: {
    vxPdpProductInfoRender: () => import(/* webpackChunkName: "vx-pdp-product-info" */ './vx-pdp-product-info.vue'),
  },
  props: {
    // Details of the products
    pdpProductInfoData: {
      type: Object,
      default: {},
    },
    // Copy text coming from properties files
    i18n: {
      type: Object,
    },
    // indicates whether the site is configured for favorites
    isFavorites: {
      type: Boolean,
      default: false,
    },
    // Id of the product
    productId: {
      type: String,
      default: '',
    },
    // indicates whether social share is enabled
    isSocialShare: {
      type: Boolean,
      default: false,
    },
    // indicates whether sample cart is enabled
    isSampleCart: {
      type: Boolean,
      default: false,
    },
    // indicates whether the site is configured for Bazaar Voice
    isBazaarVoice: {
      type: String,
      default: '',
    },
    // indicates whether the site is configured for pdf download
    showDownload: {
      type: String,
      default: '',
    },
    // colorcodes for pdf download
    colorCodes: {
      type: Object,
      default: {},
    },
    // Copy text coming from properties files for search browse components
    searchBrowseI18n: {
      type: Object,
    },
    // indicates whether to show custom tabs
    showCustomTabs: {
      type: Boolean,
      default: false,
    },
  },

};
</script>
